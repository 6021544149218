import { sdk } from '@/lib/utils/sdk';
import { captureException } from '@sentry/react';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const INITIAL_PERCENT_OFF = 0;

/**
 * If the coupon code on the URL does not represent a valid coupon, this value is returned.
 */
export const INVALID_URL_COUPON = -1;

/**
 * @returns the coupon percentage that is fetched from the URL (from the query param "coupon")
 */
const useURLCouponCode = () => {
  const [searchParams] = useSearchParams();
  const couponURL = searchParams.get('coupon');
  const isExternalCoupon = !!couponURL;
  const [externalCouponPercentOff, setExternalCouponPercentOff] =
    React.useState<number>(INITIAL_PERCENT_OFF);
  const [isLoadingExternalCouponData, setIsLoadingExternalCouponData] =
    React.useState<boolean>(false);
  const [externalCouponLinkedPromoBannerText, setExternalCouponLinkedPromoBannerText] =
    React.useState<string>('');

  const fetchUrlCouponData = async () => {
    try {
      setIsLoadingExternalCouponData(true);
      const shopifyCouponData = await sdk.GetDiscountInfo({ code: couponURL || '' });
      const result = shopifyCouponData.shopifyDiscountInfo.codeDiscount?.percentage;
      if (result) {
        setExternalCouponPercentOff(result * 100);
        setExternalCouponLinkedPromoBannerText(
          shopifyCouponData.shopifyDiscountInfo.codeDiscount?.promoBannerText || ''
        );
      } else {
        setExternalCouponPercentOff(INVALID_URL_COUPON);
      }
    } catch (e) {
      captureException(`Error fetching URL coupon ${couponURL}: ${e}`);
    } finally {
      setIsLoadingExternalCouponData(false);
    }
  };

  useEffect(() => {
    if (isExternalCoupon) {
      fetchUrlCouponData();
    }
  }, [couponURL]);

  return [
    externalCouponPercentOff,
    isLoadingExternalCouponData,
    isExternalCoupon,
    couponURL,
    externalCouponLinkedPromoBannerText
  ] as const;
};

export default useURLCouponCode;
