import { getSdk } from '@petplate/schema';
import { WEB_URL } from '@petplate/settings';
import { GUEST_TOKEN_COOKIE } from '@petplate/ui/lib/cookies';
import { GraphQLClient } from 'graphql-request';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import reduce from 'lodash/reduce';
import { NextRequest } from 'next/server';
import { readCookie, removeGlobalCookie, setGlobalCookie } from './cookies';

export const AUTH_HEADER_NAME = 'X-PetPlate-Authorization';

export const adminHeaders = (): Record<string, string> => {
  const token =
    globalThis.sessionStorage?.getItem('HTTP_X_ADMIN_TOKEN') ?? readCookie('HTTP_X_ADMIN_TOKEN');

  return token ? { X_ADMIN_TOKEN: token } : {};
};

type Credentials = {
  credentials: {
    accessToken: string;
    client: string;
    tokenType: string;
    uid: string;
  };
};

function getCredentials(res: { data: Record<string, Record<string, unknown>> }) {
  const creds =
    res?.data?.userSignUp?.credentials ||
    res?.data?.userLogin?.credentials ||
    res?.data?.userConfirmRegistrationWithToken?.credentials ||
    res?.data?.createUserForSubflow?.credentials ||
    res?.data?.assignUserToSubflow?.credentials ||
    res?.data?.userRegistrationWithToken?.credentials ||
    res?.data?.userResetPasswordWithToken?.credentials;

  if (creds) return creds as Credentials['credentials'];
  return null;
}

const serializeCreds = (creds: Credentials['credentials']) => btoa(JSON.stringify(creds));

export const deserializeCreds = (str: string | undefined): Credentials['credentials'] | null => {
  if (str) {
    return JSON.parse(atob(str));
  }

  return null;
};

// note: this *can* (and *should*) only be called client-side
// * next is currently incabable of writing cookies server-side
// * the creds will only be created as a result of a user action, all of which
//   are designed to happen client-side
export const persistCreds = (creds: Credentials['credentials'] | null) => {
  if (!creds) {
    return;
  }

  const str = serializeCreds(creds);
  setGlobalCookie(AUTH_HEADER_NAME, str);
};

// retrieved creds from cookie store
export const retrieveCreds = (req?: NextRequest) => {
  const cookieVal = readCookie(AUTH_HEADER_NAME, req);
  return deserializeCreds(cookieVal);
};

export const isSignedIn = (req?: NextRequest) => {
  const creds = retrieveCreds();
  return !isEmpty(creds);
};

// if successful logout query, delete auth cookie
const handleLogout = (resp: { data: Record<string, Record<string, unknown>> }) => {
  if (resp?.data?.userLogout?.authenticatable) {
    removeGlobalCookie(AUTH_HEADER_NAME);
  }
};

const client = new GraphQLClient(`${WEB_URL}/graphql`, {
  fetch,
  requestMiddleware: async (req) => {
    // check cookies for saved auth creds
    const creds = (() => {
      try {
        return retrieveCreds() ?? {};
      } catch (_) {
        return {};
      }
    })();

    // format creds object for headers
    const authHeaders = reduce(
      creds,
      (memo, val, key) => ({
        ...memo,
        [kebabCase(key)]: val
      }),
      {}
    );

    const guestToken = readCookie(GUEST_TOKEN_COOKIE);
    const guestTokenHeaders = guestToken
      ? { [GUEST_TOKEN_COOKIE]: guestToken }
      : ({} as Record<string, string>);

    // HACK: work around limitations of graphql-request library
    // will hopefully be fixed upstream at some point. issue to track:
    // https://github.com/jasonkuhrt/graphql-request/issues/537
    const { next, ...headers } = req.headers as HeadersInit & { next: { revalidate: number } };
    let nextConfig = {};
    if (next) {
      nextConfig = { next };
      req.headers = headers;
    }

    // append auth headers to all graphql requests
    return {
      ...req,
      ...nextConfig,
      headers: {
        ...headers,
        ...guestTokenHeaders,
        ...adminHeaders(),
        ...authHeaders
      }
    };
  },
  responseMiddleware: (resp) => {
    // check response for auth credentials
    const creds = getCredentials(resp as { data: Record<string, Record<string, unknown>> });

    // save auth creds to cookie if returned from API
    try {
      persistCreds(creds);
    } catch (err) {
      // no-op
    }

    // kill session if this is a successful logout request
    handleLogout(resp as { data: Record<string, Record<string, unknown>> });

    return resp;
  }
});
const sdk = getSdk(client);

const abortionWrap = ((sentinel) => {
  return async function* <T>(sig: AbortSignal, ait: AsyncIterable<T>): AsyncIterableIterator<T> {
    const it = ait[Symbol.asyncIterator]();
    const abortion = new Promise<IteratorResult<typeof sentinel>>((resolve) => {
      const aborted = () => resolve({ value: sentinel, done: true });
      if (sig.aborted) {
        aborted();
      } else {
        sig.addEventListener('abort', aborted, true);
      }
    });

    try {
      while (true) {
        const { done, value } = await Promise.race([abortion, it.next()]);
        if (value === sentinel || done) {
          return;
        } else {
          yield value as Awaited<T>;
        }
      }
    } finally {
      it.return?.();
    }
  };
})({});

export type QueryResult<N> = {
  nodes?: Array<N | null> | null;
  pageInfo: { endCursor?: string | null };
};

export const iterDump = async <T>(
  signal: AbortSignal,
  f: (cursor?: string) => Promise<QueryResult<T>>,
  limit?: number
): Promise<T[]> => {
  const dump = async function* (cursor?: string): AsyncIterableIterator<T> {
    const {
      nodes,
      pageInfo: { endCursor }
    } = await f(cursor);

    yield* (nodes ?? []).filter((x): x is T => x != null);
    if (endCursor) {
      yield* dump(endCursor);
    }
  };

  const acc = Array<T>();
  for await (const x of abortionWrap(signal, dump(undefined))) {
    acc.push(x);
    if (limit && acc.length >= limit) {
      break;
    }
  }

  return acc;
};

export default sdk;
