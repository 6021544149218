export enum Flows {
  FreshBaked = 'fb-subscription',
  Regular = 'subscription'
}

export enum StepGroups {
  Intro = 'intro',
  Pet = 'my-pup',
  Products = 'products'
}

export enum SubflowSteps {
  Intro = '/intro',
  Name = '/name',
  Email = '/email',
  GenderBreed = '/gender',
  Age = '/age',
  WeightActivity = '/weight',
  Waistline = '/waistline',
  SensitivitiesGoals = '/sensitivities',
  CalculatingPlan = '/calculating-plan',
  Plans = '/plans',
  Plan = '/plan',
  Recipes = '/recipes',
  Treats = '/treats',
  Supplements = '/supplements',
  PlanConfirmation = '/plan-confirmation'
}

export type StepPath = keyof {
  [S in SubflowSteps as `/${Flows}${S}`]: true;
};

export type Step = {
  path: SubflowSteps;
  group: StepGroups;
  fullPath: StepPath;
};
