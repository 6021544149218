import { Flows, StepGroups, SubflowSteps, type Step } from '@/types/steps';

export const FreshBakedFlow = [
  {
    path: SubflowSteps.Intro,
    fields: ['introViewed'],
    group: StepGroups.Intro
  },
  {
    path: SubflowSteps.Name,
    fields: ['petName'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.Email,
    fields: ['contactEmail'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.GenderBreed,
    fields: ['gender', 'primaryBreedId', 'spayedNeutered'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.Age,
    fields: ['birthYear'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.WeightActivity,
    fields: ['weight', 'activityLevel'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.Waistline,
    fields: ['waistline'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.CalculatingPlan,
    fields: [],
    group: StepGroups.Products
  },
  {
    path: SubflowSteps.Plan,
    fields: ['planType', 'planId', 'recipeIds'],
    group: StepGroups.Products
  },
  {
    path: SubflowSteps.Treats,
    fields: [],
    group: StepGroups.Products
  },
  {
    path: SubflowSteps.Supplements,
    fields: [],
    group: StepGroups.Products
  },
  {
    path: SubflowSteps.PlanConfirmation,
    fields: [],
    group: StepGroups.Products
  }
] as const;

export const RegularFlow = [
  {
    path: SubflowSteps.Intro,
    fields: ['introViewed'],
    group: StepGroups.Intro
  },
  {
    path: SubflowSteps.Name,
    fields: ['petName', 'contactName'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.Email,
    fields: ['contactEmail'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.GenderBreed,
    fields: ['gender', 'primaryBreedId', 'spayedNeutered'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.Age,
    fields: ['birthYear'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.WeightActivity,
    fields: ['weight', 'activityLevel'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.Waistline,
    fields: ['waistline'],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.SensitivitiesGoals,
    fields: [],
    group: StepGroups.Pet
  },
  {
    path: SubflowSteps.CalculatingPlan,
    fields: [],
    group: StepGroups.Products
  },
  {
    path: SubflowSteps.Plans,
    fields: ['planType', 'planId'],
    group: StepGroups.Products
  },
  {
    path: SubflowSteps.Recipes,
    fields: ['recipeIds'],
    group: StepGroups.Products
  },
  {
    path: SubflowSteps.Treats,
    fields: [],
    group: StepGroups.Products
  },
  {
    path: SubflowSteps.Supplements,
    fields: [],
    group: StepGroups.Products
  },
  {
    path: SubflowSteps.PlanConfirmation,
    fields: [],
    group: StepGroups.Products
  }
] as const;

export const regularSteps: Step[] = RegularFlow.map(({ path, group }) => ({
  path,
  fullPath: `/${Flows.Regular}${path}`,
  group
}));

export const freshBakedSteps: Step[] = FreshBakedFlow.map(({ path, group }) => ({
  path,
  fullPath: `/${Flows.FreshBaked}${path}`,
  group
}));
