import { SubflowSteps } from '@/types/steps';

export type ProgressBarStep = {
  title: string;
  substeps: (typeof SubflowSteps)[keyof typeof SubflowSteps][];
};

/**
 * NOTE: Kind of duplicate code that doesn't respect multiple flow, but works for now
 */
export const PROGRESS_BAR_STEPS: ProgressBarStep[] = [
  {
    title: 'Parent',
    substeps: [SubflowSteps.Intro, SubflowSteps.Name, SubflowSteps.Email]
  },
  {
    title: 'Pet',
    substeps: [
      SubflowSteps.GenderBreed,
      SubflowSteps.Age,
      SubflowSteps.WeightActivity,
      SubflowSteps.Waistline,
      SubflowSteps.SensitivitiesGoals
    ]
  },
  {
    title: 'Plan',
    substeps: [SubflowSteps.Plan, SubflowSteps.Plans, SubflowSteps.Recipes]
  },
  {
    title: 'Checkout',
    substeps: [SubflowSteps.Treats, SubflowSteps.Supplements, SubflowSteps.PlanConfirmation]
  }
];
