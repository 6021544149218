import { getSdk } from '@petplate/schema';
import { SUBFLOW_URL, WEB_URL } from '@petplate/settings';
import { AUTH_HEADER_COOKIE, GUEST_TOKEN_COOKIE } from '@petplate/ui/lib/cookies';
import { GraphQLClient } from 'graphql-request';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import reduce from 'lodash/reduce';
import { cookiesManager } from './cookiesManager';

export type QueryResult<N> = {
  nodes?: Array<N | null> | null;
  pageInfo: { endCursor?: string | null };
};

type Credentials = {
  credentials: {
    accessToken: string;
    client: string;
    tokenType: string;
    uid: string;
  };
};

export const getCredentials = (res: { data: Record<string, Record<string, unknown>> }) => {
  const creds =
    res?.data?.userSignUp?.credentials ||
    res?.data?.userLogin?.credentials ||
    res?.data?.userConfirmRegistrationWithToken?.credentials ||
    res?.data?.createUserForSubflow?.credentials ||
    res?.data?.assignUserToSubflow?.credentials ||
    res?.data?.userRegistrationWithToken?.credentials ||
    res?.data?.userResetPasswordWithToken?.credentials;

  if (creds) return creds as Credentials['credentials'];
  return null;
};

export const serializeCreds = (creds: Credentials['credentials']) => btoa(JSON.stringify(creds));

export const deserializeCreds = (str: string | undefined): Credentials['credentials'] | null => {
  if (str) {
    return JSON.parse(atob(str));
  }
  return null;
};

export const persistCreds = (creds: Credentials['credentials'] | null) => {
  if (!creds) {
    return;
  }
  const str = serializeCreds(creds);
  cookiesManager.set(AUTH_HEADER_COOKIE, str);
};

// retrieved creds from cookie store
export const retrieveCreds = () => {
  const cookieVal = cookiesManager.get(AUTH_HEADER_COOKIE);
  return deserializeCreds(cookieVal);
};

export const isSignedIn = () => {
  const creds = retrieveCreds();
  return !isEmpty(creds);
};

// if successful logout query, delete auth cookie
export const handleLogout = (resp: { data: Record<string, Record<string, unknown>> }) => {
  if (resp?.data?.userLogout?.authenticatable) {
    cookiesManager.remove(AUTH_HEADER_COOKIE);
  }
};

// NOTE: Locally GraphQL URL has to be subflow app url, to avoid CORS issues
const GRAPHQL_DOMAIN_URL = process.env.LOCAL ? SUBFLOW_URL : WEB_URL;

const client = new GraphQLClient(`${GRAPHQL_DOMAIN_URL}/graphql`, {
  fetch,
  requestMiddleware: async (req) => {
    // check cookies for saved auth creds
    const creds = (() => {
      try {
        return retrieveCreds() ?? {};
      } catch (_) {
        return {};
      }
    })();

    // format creds object for headers
    const authHeaders = reduce(
      creds,
      (memo, val, key) => ({
        ...memo,
        [kebabCase(key)]: val
      }),
      {}
    );

    const guestToken = cookiesManager.get(GUEST_TOKEN_COOKIE);
    const guestTokenHeaders = guestToken
      ? { [GUEST_TOKEN_COOKIE]: guestToken }
      : ({} as Record<string, string>);

    // append auth headers to all graphql requests
    return {
      ...req,
      headers: {
        ...req.headers,
        ...guestTokenHeaders,
        ...authHeaders
      }
    };
  },
  responseMiddleware: (resp) => {
    // check response for auth credentials
    const creds = getCredentials(resp as { data: Record<string, Record<string, unknown>> });

    // save auth creds to cookie if returned from API
    try {
      persistCreds(creds);
    } catch (_) {
      // no-op
    }

    // kill session if this is a successful logout request
    handleLogout(resp as { data: Record<string, Record<string, unknown>> });

    return resp;
  }
});

export const sdk = getSdk(client);
