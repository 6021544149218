import { Coupon } from '@petplate/schema/*';
import { COUPON_SETTINGS_KEYS } from '@petplate/utils/coupons';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import useURLCouponCode, { INVALID_URL_COUPON } from './hooks/useURLCouponCode';
import { sdk } from './utils/sdk';
export type CouponSetting = {
  key: string;
  value: Partial<Coupon>;
};

export const useSiteWideDiscounts = () => {
  try {
    const [resp, setResp] = useState<CouponSetting[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchSiteWideDiscounts = async () => {
      setIsLoading(true);
      try {
        const settingsData = await sdk.GetSettings({ vars: Object.values(COUPON_SETTINGS_KEYS) });

        const couponSettings =
          settingsData.settings?.map((s) => ({ key: s.key, value: s.value as Partial<Coupon> })) ||
          [];

        setResp(couponSettings);
      } catch (e) {
        captureException(e);
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchSiteWideDiscounts();
    }, []);

    const [
      externalCouponPercentOff,
      isLoadingExternalCouponData,
      isExternalCoupon,
      couponURL,
      externalCouponLinkedPromoBannerText
    ] = useURLCouponCode();

    const urlCouponDataInvalid = externalCouponPercentOff === INVALID_URL_COUPON;
    let siteWideCoupon = resp.find((s) => s.key === COUPON_SETTINGS_KEYS.SITE_WIDE_COUPON)?.value;

    if (isExternalCoupon && !urlCouponDataInvalid) {
      siteWideCoupon = {
        code: couponURL || '',
        percentOff: externalCouponPercentOff,
        amountOff: 0,
        maskedCode: '',
        promoBannerText: externalCouponLinkedPromoBannerText
      };
    }

    const siteWideBackupCode = resp.find(
      (s) => s.key === COUPON_SETTINGS_KEYS.SITE_WIDEBACKUP_CODE
    )?.value;
    const subCancelledEntreesDiscount = resp.find(
      (s) => s.key == COUPON_SETTINGS_KEYS.SUB_CANCELLED_ENTREES_DISCOUNT
    )?.value;

    return {
      siteWideCoupon,
      siteWideBackupCode,
      subCancelledEntreesDiscount,
      isLoading: isLoading || isLoadingExternalCouponData
    };
  } catch (err) {
    console.error(err);
    captureException(err);

    return {
      siteWideCoupon: {},
      siteWideBackupCode: {},
      subCancelledEntreesDiscount: {},
      isLoading: false
    };
  }
};
