import { SubscriptionShopifyCoupon } from '@petplate/schema/*';
import sdk, { iterDump } from '@petplate/sdk';
import { cache } from '@petplate/utils/cache';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import { GraphqlExecutionError } from '../ui/types/errors';

export const COUPON_SETTINGS_KEYS = {
  SITE_WIDE_COUPON: 'site_wide_coupon',
  SITE_WIDEBACKUP_CODE: 'site_wide_backup_code',
  SUB_CANCELLED_ENTREES_DISCOUNT: 'sub_cancelled_entrees_discount',
  HIGH_LTV_DISCOUNT: 'high_ltv_coupon'
};

export const buildCouponUpdateErrorMessage = (err: GraphqlExecutionError) => {
  // The non stackable error is a custom one that we throw in the backend when the coupons are not stackable.
  const couponsNotStackableError = err.response.errors.find(
    (e) => e?.extensions?.code === 'NON_STACKABLE_COUPONS'
  );

  const usageLimitReachedError = err.response.errors.find((e) =>
    e.message?.match(/CUSTOMER_USAGE_LIMIT_REACHED/)
  );

  const defaultError =
    'Coupon(s) could not be applied to your subscription. Contact the support team.';

  if (couponsNotStackableError) {
    // This error message contains the actual coupons that couldn't be stacked, so use the backend message in this case
    // instead of trying to see which coupons were not stackable manually.
    return err.response.errors[0]?.message || defaultError;
  }

  if (usageLimitReachedError) {
    return "You've already used this coupon";
  }

  return defaultError;
};

export const loadCoupons = (limit: number) =>
  cache(
    Object.assign(
      async (a: AbortSignal, title: string) => {
        const coupons = await iterDump(
          a,
          async (cursor) => (await sdk.AdminSearchCoupons({ title, cursor })).shopifyCoupons,
          limit
        );

        return Object.fromEntries(coupons.map(({ code, title }) => [code, title ?? code]));
      },
      { many: true }
    )
  );

export enum CouponActionName {
  Remove = 'remove',
  NotApplicable = 'notApplicable',
  Stick = 'stick'
}

export type CouponAction = {
  coupon: Partial<SubscriptionShopifyCoupon>;
  action: CouponActionName;
  items?: string[];
};

/**
 *
 * @param coupon the coupon
 * @returns true if the coupon is limited by usage cycle or once per customer
 */
export const isLimited = (coupon: Partial<SubscriptionShopifyCoupon>) =>
  (typeof coupon.usageCycleLimit === 'number' && coupon.usageCycleLimit > 0) ||
  coupon.oncePerCustomer === true;

/**
 *
 * @param coupons The current coupons on the subscription.
 * @param itemSkus The new items snapshot on the subscription
 * @returns a list of actions that need to be taken on each coupon.
 *
 * The actions are:
 * - Remove: The coupon needs to be removed from the subscription. This happens if the system detects that a coupon
 *    would no longer be applicable on a subscription because it is limitted (which means the coupon cannot be re-applied).
 *
 * - NotApplicable: The coupon is not applicable to the new items, but can still remain on the subscription.
 *    This happens if new products are added but cannot be discounted by the current coupons.
 *
 * - Stick: The coupon is still applicable to the new items and does not need to be re-applied.
 */
export const couponActions = (
  coupons: Partial<SubscriptionShopifyCoupon>[],
  itemSkus: string[]
): CouponAction[] => {
  return (
    coupons?.map((coupon) => {
      const entitledCouponItemSkus = coupon.entitledProducts?.map((p) => p.sku || '') || [];
      const commonItems = intersection(entitledCouponItemSkus, itemSkus);

      const appliesOnNewItems = coupon.appliesToAllItems || commonItems.length > 0;

      if (!appliesOnNewItems && isLimited(coupon)) {
        return {
          coupon,
          action: CouponActionName.Remove
        };
      }

      const itemsNotEligible = itemSkus.filter((sku) => !entitledCouponItemSkus.includes(sku));

      if (!coupon.appliesToAllItems && itemsNotEligible.length > 0) {
        return {
          coupon,
          action: CouponActionName.NotApplicable,
          items: itemsNotEligible
        };
      }

      return {
        coupon,
        action: CouponActionName.Stick
      };
    }) || []
  );
};

/**
 *
 * @param coupon The coupon applied on the subscription
 * @param itemSku The item sku that is being added to the subscription
 * @returns true if this item can still be discounted by the subscription
 */
export const willItemBeDiscounted = (
  coupon: Partial<SubscriptionShopifyCoupon>,
  itemSku: string
) => {
  if (coupon.appliesToAllItems === true) {
    return true;
  }

  if (coupon.entitledProducts?.find((p) => p.sku === itemSku)) {
    return true;
  }

  if (isLimited(coupon)) {
    return false; // We cannot re-apply the coupon to discount the new item
  }

  return true; // We can reapply the coupon
};

/**
 *
 * @param coupon The coupon applied on the subscription
 * @param addedItems the list of item skus added to the subscription
 * @param removedItems the list of item skus removed from the subscription
 * @returns true if the coupon will be removed from the subscription by the system with the new items state.
 *
 * The system removes the coupon if it detects that it cannot re-apply the coupon after changing the items.
 */
export const willCouponBeRemoved = (
  coupon: Partial<SubscriptionShopifyCoupon>,
  addedItems: string[],
  removedItems: string[]
) => {
  if (addedItems.length > 0 && coupon.appliesToAllItems === true) {
    return false;
  }

  const entitledProductSkus = coupon.entitledProducts?.map((p) => p.sku ?? '') || [];

  // Either the coupon applies to all items, or already applies to the new items, or the coupon definition
  // contains the new added items.
  const appliesToAnyNewItem =
    coupon.appliesToAllItems ||
    addedItems.some((item) => entitledProductSkus.includes(item)) ||
    intersection(coupon.applicableProducts, addedItems).length > 0;

  const willHaveNoMoreEntitledLines = difference(entitledProductSkus, removedItems).length === 0;

  // We cannot re-apply a limited coupon (so no need to check the new added items) and this coupon will be left with
  // no entitled lines, so we need to remove it.
  if (isLimited(coupon) && willHaveNoMoreEntitledLines) {
    return true;
  }

  // If the coupon is not limited and it doesn't apply to any new item or will be left with no entitled items, remove it.
  if (!isLimited(coupon) && !appliesToAnyNewItem && willHaveNoMoreEntitledLines) {
    return true;
  }

  return false;
};
