import { sdk } from '@/lib/utils/sdk';
import { CurrentUser } from '@/types/currentUser';
import { type LineItemData, type RecWizard } from '@/types/recWizard';
import { Flows, SubflowSteps, type Step } from '@/types/steps';
import {
  RecommendationWizardPlanTypeEnum as PlanTypes,
  type PetPlateProduct,
  type RecommendationWizardInput
} from '@petplate/schema';
import { GUEST_TOKEN_COOKIE } from '@petplate/ui/lib/cookies';
import { LocalStorageService, REC_WIZARD_KEY } from '@petplate/ui/lib/storage';
import { captureException } from '@sentry/react';
import { type CartLine, type CartLineInput } from '@shopify/hydrogen-react/storefront-api-types';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import reduce from 'lodash/reduce';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import { DateTime } from 'luxon';
import { cookiesManager } from './cookiesManager';
import { FreshBakedFlow, RegularFlow, freshBakedSteps, regularSteps } from './steps';

const getStepsForFlow = (flow: Flows) => {
  switch (flow) {
    case Flows.FreshBaked:
      return freshBakedSteps;
    case Flows.Regular:
    default:
      return regularSteps;
  }
};

const flowConfigs = {
  [Flows.FreshBaked]: FreshBakedFlow,
  [Flows.Regular]: RegularFlow
};

const getFirstStep = (flow: Flows) => first(getStepsForFlow(flow))?.fullPath as Step['fullPath'];

// check if arr1 contains all elements from arr2
const containsAllElements = (arr1: string[], arr2: string[]) => {
  return arr1.every((element) => arr2.includes(element));
};

// find the latest step where all dependencies have been met
// if all deps have been met for specified step, return specified step
export const getLatestDependantStep = (
  flow: Flows,
  step: Step['path'],
  wizard: Partial<RecWizard>
) => {
  const flowConfig = flowConfigs[flow];
  const stepIndex = Math.max(
    0,
    flowConfig.findIndex((s) => s.path === step)
  );
  const requiredFields = [] as (typeof flowConfig)[number]['fields'][number][];

  // create array of all completed field names
  const completedFields = keys(wizard).reduce((memo, key) => {
    if (
      !isEmpty(wizard[key]) ||
      typeof wizard[key] === 'number' ||
      typeof wizard[key] === 'boolean'
    ) {
      memo.push(key as keyof typeof wizard);
    }
    return memo;
  }, [] as (keyof typeof wizard)[]);

  // latest step that has completed all required fields up to specified step
  let maxStep: Step['path'] = flowConfig[0].path;

  // return intro step if not marked as viewed
  if (!wizard.introViewed) return maxStep;

  // iterate through all steps in order, checking if each step has met all
  // dependencies. if deps have not been met for current step, return route for
  // previous step
  for (let s = 0; s <= stepIndex; s += 1) {
    if (!containsAllElements(requiredFields, completedFields)) {
      return maxStep;
    }
    requiredFields.push(...flowConfig[s].fields);
    maxStep = flowConfig[s].path;
  }

  // return specified step if all deps have been met
  return step;
};

// given the current step and wizard contents, determine latest allowed step.
// redirect to that step if it comes before the current step
export const validateStepOrRedirect = (
  flow: Flows,
  step: Step['fullPath'],
  wizard?: Partial<RecWizard> | null
) => {
  if (!wizard?.id) {
    return getFirstStep(flow);
  }

  const parsedStep = `/${step.split('/').pop()}` as Step['path'];
  const latestDependantStep = getLatestDependantStep(flow, parsedStep, wizard);

  if (latestDependantStep !== parsedStep) {
    const toUrl = `/${flow}${latestDependantStep}`;
    return toUrl as Step['fullPath'];
  }

  // If somehow the guest token cookie is not defined, but it's in the cart definition
  // then we set the cookie correctly again
  const currentGuestToken = cookiesManager.get(GUEST_TOKEN_COOKIE) || '';
  if (wizard.guestToken && !currentGuestToken) {
    cookiesManager.set(GUEST_TOKEN_COOKIE, wizard.guestToken);
  }

  return wizard;
};

/**
 * Updates a recommendation wizard in the DB,
 * based on the values of the new RecWizard from the cookie
 *
 * @param wizard {RecWizard} a new recwizard object, usually
 */
export const syncRecWizard = async (wizard: Partial<RecWizard>) => {
  const resp = await sdk.UpdateRecWizard({
    id: wizard.id as string,
    input: convertWizardToApiData(wizard)
  });
  return resp;
};

/**
 * Updates the current session RecWizard cookie
 */
export const updateSessionWizard = (wizard: Partial<RecWizard>) => {
  LocalStorageService.set(REC_WIZARD_KEY, wizard);
};

/**
 * Creates a new RecWizard
 * Works for both logged users and guests
 *
 * @returns a new RecWizard
 */
export const createSessionWizard = async (flow: Flows, currentUser?: CurrentUser) => {
  try {
    // Create wizard in the API, to retrieve the "id" and "guestToken"
    const userId = currentUser ? toNumber(currentUser.id) : undefined;
    const { recommendationWizardCreate } = await sdk.CreateRecWizard({ userId });
    const guestToken = recommendationWizardCreate?.recommendationWizard.guestToken;
    const wizard: Partial<RecWizard> = {
      id: recommendationWizardCreate?.recommendationWizard.id,
      guestToken,
      userId: currentUser?.id,
      contactEmail: currentUser?.email,
      contactName: currentUser?.name,
      latestStep: `/${flow}${SubflowSteps.Intro}`,
      introViewed: true,
      // make sure new wizards have this set to false
      // to force a shopify cart clear
      cartCreated: false
    };
    // Set guest token cookie for current wizard
    cookiesManager.set(GUEST_TOKEN_COOKIE, guestToken as string);
    // Update wizard cookie
    updateSessionWizard(wizard);
    return wizard;
  } catch (err) {
    captureException(err);
    throw Error('Failed to create new recommendation wizard.');
  }
};

export const importExistingWizard = async (wizardId: string) => {
  try {
    const { recommendationWizard } = await sdk.FindRecommendationWizard({ id: wizardId });
    const wizard = buildWizardFromApiData(recommendationWizard);
    updateSessionWizard(wizard);
    return wizard;
  } catch (err) {
    captureException(err);
    throw Error('Failed to import recommendation wizard.');
  }
};

/**
 * Deletes the current recommendation wizard cookie
 */
export const clearSessionWizard = () => {
  LocalStorageService.delete(REC_WIZARD_KEY);
};

/**
 * Marks the current recommendation wizard as completed on the cookie
 */
export const markWizardComplete = () => {
  const storedWizard = (LocalStorageService.get(REC_WIZARD_KEY) || {}) as Partial<RecWizard>;
  updateSessionWizard({ ...storedWizard, completed: true });
};

/**
 * Checks if the currently stored wizard is complete or not
 * If the latest known step for the wizard is /plan-confirmation
 * then we need to make an API call to confirm if it has actually been completed
 * because we may have old data in the LocalStorage
 *
 * @param {Partial<RecWizard>} wizard the wizard to check
 * @returns {boolean} the wizard completion status
 */
export const isWizardCompleted = async (wizard: Partial<RecWizard>) => {
  try {
    if (wizard.completed) return true;
    else if (wizard.id && wizard.latestStep?.includes(SubflowSteps.PlanConfirmation)) {
      const { recommendationWizard } = await sdk.CheckWizardCompleted({ id: wizard.id });
      return recommendationWizard.completed;
    }
    return false;
  } catch (e) {
    captureException(e);
    // If the API request fails we should consider the wizard as completed
    // to be able to start over
    return true;
  }
};

/**
 * Fetches or creates a recommendation wizard for the current user
 *
 * @param step - the URL path of a step in the subflow
 * @returns a RecWizard (or redirects to the beginning if undefined)
 */
export const fetchSessionWizard = async ({
  flow = Flows.Regular,
  step,
  currentUser
}: {
  flow?: Flows;
  step: Step['fullPath'];
  currentUser?: Partial<CurrentUser>;
}) => {
  // Fetch wizard data from the client cookie
  const storedWizard = (LocalStorageService.get(REC_WIZARD_KEY) || {}) as Partial<RecWizard>;
  const firstStepRoute = getFirstStep(flow);

  // If there is no wizard yet in the session and the user
  // is trying to access a step further down the flow,
  // redirect to the beginning
  if (isEmpty(storedWizard) && step !== firstStepRoute) {
    return firstStepRoute;
  }

  // Check if wizard is completed
  const wizardCompleted = await isWizardCompleted(storedWizard);

  // Else check if the wizard has valid params for the current step
  // and redirect to a previous step if it's not progressed enough
  // or create a new one if the wizard data is corrupted/invalid
  if (storedWizard.id && !wizardCompleted) {
    return validateStepOrRedirect(flow, step, storedWizard);
  } else if (step === firstStepRoute) {
    // Clear any potential previous wizard data
    clearSessionWizard();
    return await createSessionWizard(flow, currentUser);
  } else {
    return firstStepRoute;
  }
};

/**
 * Maps Shopify cart lines to JSON data stored on the recommendation wizards
 *
 * @param lines the Shopify cart lines
 * @param products our data regarding the offered products (because the SKU is missing in the lines data)
 * @returns a JSON object with the format that we use when saving "line_items_data" to our DB records
 */
export const convertLinesToWizardData = (lines: CartLine[], products?: PetPlateProduct[]) => {
  const addonLines = filter(lines, (l) => findIndex(l.attributes, { value: 'addon' }) !== -1);

  return map(addonLines, (l) => {
    const addonProduct = find(products, { shopifyId: l.merchandise.id });
    return {
      price: addonProduct?.price || toNumber(l.cost.subtotalAmount.amount),
      type: 'addon',
      shopify_id: l.merchandise.id,
      sku: addonProduct?.sku,
      quantity: l.quantity,
      recurring: true,
      discount: reduce(
        l.discountAllocations,
        (acc, discAlloc) => {
          return acc + toNumber(discAlloc.discountedAmount.amount);
        },
        0
      )
    };
  });
};

/**
 * Rebuild the Shopify cart from an existing wizard
 *
 * @param wizard the wizard to rebuild from
 * @returns the cart line items to initialize a new cart with
 */
export const rebuildShopifyCartLines = (wizard: Partial<RecWizard>): CartLineInput[] => {
  const cartLines: CartLineInput[] = [];
  if (wizard.planId && wizard.sellingPlanId) {
    // Add plan
    cartLines.push({
      attributes: [{ key: '_type', value: 'plan' }],
      merchandiseId: wizard.planShopifyId as string,
      sellingPlanId: wizard.sellingPlanId,
      quantity: 1
    });
    // Add recipes if already selected
    if (!isEmpty(wizard.recipeIds)) {
      forEach(wizard.recommendedRecipeLineItems, (recipe) => {
        cartLines.push({
          attributes: [{ key: '_type', value: 'recipe' }],
          merchandiseId: recipe.shopifyId,
          sellingPlanId: wizard.sellingPlanId,
          quantity: recipe.quantity
        });
      });
    }
    // Add saved addons, if any
    if (wizard.lineItemsData) {
      forEach(wizard.lineItemsData, (line: LineItemData) => {
        cartLines.push({
          attributes: [{ key: '_type', value: 'addon' }],
          merchandiseId: line.shopify_id,
          sellingPlanId: wizard.sellingPlanId,
          quantity: line.quantity
        });
      });
    }
  }
  return cartLines;
};

/**
 * Converts a subflow RecWizard into a RecommendationWizardInput object
 *
 * @param wizard the wizard to convert to API data
 * @returns an object of type RecommendationWizardInput (check GraphQL schema)
 */
export const convertWizardToApiData = (
  wizard: Partial<RecWizard>
): Partial<RecommendationWizardInput> => {
  const birthDate = wizard.birthYear
    ? DateTime.fromObject({
        year: wizard.birthYear,
        month: wizard.birthMonth || 1,
        day: wizard.birthDay || 1
      })
    : undefined;

  return omitBy(
    {
      contactEmail: wizard.contactEmail,
      contactName: wizard.contactName,
      // NOTE: when not defined, must send "userId" null,
      // to de-associate the user from the wizard in the DB
      // Cannot send "undefined", because the param
      // will simply be inored in that case
      userId: wizard.userId ? toNumber(wizard.userId) : null,
      // Pet data
      petName: wizard.petName,
      petId: wizard.petId ? toNumber(wizard.petId) : undefined,
      birthDate: birthDate?.isValid ? birthDate.toISODate() : undefined,
      gender: wizard.gender,
      spayedNeutered: parseBoolean(wizard.spayedNeutered),
      primaryBreedId: wizard.primaryBreedId ? toNumber(wizard.primaryBreedId) : undefined,
      secondaryBreedId:
        wizard.secondaryBreedId && wizard.secondaryBreedId !== '0'
          ? toNumber(wizard.secondaryBreedId)
          : undefined,
      weight: wizard.weight,
      waistline: wizard.waistline,
      activityLevel: wizard.activityLevel,
      wellnessGoalIds: wizard.wellnessGoalIds,
      hasWellnessGoals: wizard.hasGoals,
      ingredientIds: wizard.foodSensitivityIds,
      hasFoodSensitivities: wizard.hasSensitivities,
      // Plan/recipes/addons data
      planId: wizard.planId ? toNumber(wizard.planId) : undefined,
      planType: wizard.planType,
      recipeIds: wizard.recipeIds,
      lineItemsData: wizard.lineItemsData,
      // Metadata
      latestStep: wizard.latestStep?.substring(1)
    },
    isUndefined
  );
};

/**
 * Converts an API RecommendationWizard into a subflow RecWizard
 *
 * @param wizard the RecommendationWizard returned by the API
 * @returns an object of type RecWizard, to store in the subflow
 */
export const buildWizardFromApiData = (
  wizard: Awaited<ReturnType<typeof sdk.FindRecommendationWizard>>['recommendationWizard']
): Partial<RecWizard> => {
  const birthDate = wizard.birthDate ? DateTime.fromISO(wizard.birthDate) : undefined;
  const isBirthDateValid = birthDate?.isValid;

  return omitBy(
    {
      id: wizard.id,
      guestToken: wizard.guestToken,
      userId: wizard.userId ? toString(wizard.userId) : undefined,
      contactEmail: wizard.contactEmail,
      contactName: wizard.contactName,
      // Pet data
      petName: wizard.petName,
      petId: wizard.petId ? toString(wizard.petId) : undefined,
      gender: wizard.gender,
      spayedNeutered: isNil(wizard.spayedNeutered) ? undefined : toString(wizard.spayedNeutered),
      primaryBreedId: wizard.primaryBreedId ? toString(wizard.primaryBreedId) : undefined,
      secondaryBreedId: wizard.secondaryBreedId ? toString(wizard.secondaryBreedId) : undefined,
      selectedBreedOptions: wizard.selectedBreedOptions,
      birthYear: isBirthDateValid ? birthDate.year : undefined,
      birthMonth: isBirthDateValid ? birthDate.month : undefined,
      birthDay: isBirthDateValid ? birthDate.day : undefined,
      weight: wizard.weight,
      waistline: wizard.waistline,
      activityLevel: wizard.activityLevel,
      hasSensitivities: wizard.hasFoodSensitivities,
      foodSensitivityIds: wizard.ingredientIds,
      hasGoals: wizard.hasWellnessGoals,
      wellnessGoalIds: wizard.wellnessGoalIds,
      // Plan, recipes and addons data
      planId: wizard.planId ? toString(wizard.planId) : undefined,
      planSku: wizard.plan?.sku,
      planType: wizard.planType,
      planBoxType: wizard.plan?.planType,
      planShopifyId: wizard.plan?.shopifyId,
      planAddonUnits: wizard.plan?.addonUnits,
      sellingPlanId: wizard.plan?.sellingPlanId,
      recommendedRecipeLineItems: wizard.recommendedRecipeLineItems,
      recipeIds: wizard.recipeIds || [],
      bakedIds: includes([PlanTypes.Baked, PlanTypes.Combo], wizard.planType)
        ? wizard.bakedIds
        : [],
      cookedIds: wizard.planType && wizard.planType !== PlanTypes.Baked ? wizard.cookedIds : [],
      lineItemsData: wizard.lineItemsData || [],
      // Metadata
      latestStep: wizard.latestStep ? `/${wizard.latestStep}` : SubflowSteps.Intro,
      treatsOffered: !isEmpty(wizard.lineItemsData),
      readyForCheckout: SubflowSteps.PlanConfirmation === wizard.latestStep,
      introViewed: wizard.latestStep ? SubflowSteps.Intro !== wizard.latestStep : false,
      completed: false,
      cartCreated: false
    },
    isUndefined
  ) as Partial<RecWizard>;
};

// Because the options that we send to the recommendation wizard are
// usually strings we need a way of converting a string into a boolean.
// A direct comparison gives typescript warnings because the wizard is
// already expecting a boolean.
export const parseBoolean = (value: unknown): boolean | undefined => {
  if (typeof value === 'string') {
    return value === 'true';
  } else if (typeof value === 'boolean') {
    return value;
  } else {
    // maintains the same value on the backend side
    return undefined;
  }
};
